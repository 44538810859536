import {RoutesNamesEnum} from '~/app/router.options';
import type {UserInfo} from '~/stores/user';

export default defineNuxtRouteMiddleware(async ({params, name}) => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const id = params.id as string;

  const {fetchServiceProvider, fetchUser, setCurrentUser} = useUserStore();

  const endpoint =
    name === RoutesNamesEnum.ServiceProviderUserProfile
      ? fetchServiceProvider
      : fetchUser;

  const {status, data} = await endpoint(id);

  if (status.value === 'success') {
    setCurrentUser(data.value as UserInfo);
  }
});
